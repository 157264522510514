.menu-item {
  box-shadow: rgb(0 0 0 / 12%) 0px 2px 4px;
  margin: 0;
  text-align: center;
  text-transform: uppercase;
}

.menu-item ion-img {
  height: 120px;
  width: 120px;
  margin: auto;
}

.logo {
  width: 150px;
  margin: 20px auto;
}

.grid {
  margin-bottom: 70px;
}
