.html-content {
  padding: 16px;
}

.info-img {
  box-sizing: border-box;
  width: calc(100% - 32px);
  margin-left: 16px;
  margin-right: 16px;
}
