ion-range {
  padding: 0 16px;
}

.player-controls {
  padding-bottom: 10px;
  width: 100%;
  pointer-events: auto;
}

.player-controls ion-icon + ion-icon {
  margin-left: 8px !important;
}

ion-icon[disabled="true"] {
  opacity: 0.5;
}

ion-spinner {
  margin-right: 8px;
}
