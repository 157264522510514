.form p {
  line-height: 1.5;
  margin-bottom: 0;
  margin-left: 20px;
}

.form .button {
  margin: 20px;
}

.form .error {
  margin-left: 20px;
}

.track-item .sc-ion-label-ios-h {
  white-space: normal;
}

.track-item .sc-ion-label-ios-h h4 {
  font-size: 1.1em;
  font-weight: bold;
  max-width: calc(100% - 20px);
  white-space: nowrap;
}

.description {
  padding: 0 16px;
}

ion-item {
  font-size: 1.2em;
}

.sc-ion-label-ios-s p {
  font-size: 1.1em;
}
