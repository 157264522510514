.description-panel {
  bottom: 0;
  height: 400px;
  margin: 16px;
  transition: bottom 0.2s;
  min-width: calc(100% - 36px);
}

.description-panel.closed {
  bottom: -250px;
  transition: bottom 0.2s;
}

.description-panel p {
  margin-top: 0.5em;
  font-size: 1em;
}

.description-panel .player {
  margin-left: -5px;
  height: 54px;
}

.description-panel .title {
  height: 32px;
}

.toggle-arrow {
  position: absolute;
  right: 16px;
  top: 16px;
  margin: 0;
  font-size: 1.8em;
}

.track-image {
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
}

.icon-button {
  font-size: 3em;
}

.play-spinner {
  margin: 10px;
}

.description-panel h2 {
  color: black;
  font-weight: bold;
  margin-bottom: 12px;
}

.html-scroll {
  max-height: 224px;
  height: 224px;
  overflow-y: auto;
}

.icon-video {
  border: 1px solid var(--ion-color-light);
  border-radius: 50%;
  box-sizing: border-box;
  float: right;
  font-size: 24px;
  margin-top: 4px;
  padding: 8px;
}

.icon-heart {
  border: 1px solid var(--ion-color-light);
  border-radius: 50%;
  box-sizing: border-box;
  float: right;
  font-size: 24px;
  margin-top: 4px;
  margin-left: 4px;
  padding: 8px;
}

.video-player {
  position: absolute;
  text-align: center;
  top: 15%;
  left: 0;
  width: 100%;
}

ion-spinner[size="lg"] {
  width: 50px;
  height: 50px;
}
