.track-item {
  --inner-border-width: 0;
}

.heart-button {
  display: block;
  position: absolute;
  right: 14px;
}

.delete-button {
  display: block;
  position: absolute;
  right: 48px;
}

ion-icon[size="regular"] {
  font-size: 24px;
}
